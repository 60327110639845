<template>

    <div class="h-auto flex flex-col px-2">

        <div class="h-10 flex flex-row flex-none b">

            <div class="h-full flex flex-row justify-start items-center w-2/12">
                <span class="text-xl font-semibold" :class="percentageColor(this.opportunity.probability)">{{this.opportunity.probability}}%</span>
            </div>

            <div class="h-full flex flex-row justify-start items-center w-10/12">
                <span class="font-semibold truncate">{{ this.opportunity.opportunity_name }}</span>
            </div>

        </div>

        <div class="h-8 flex flex-row flex-none">

            <div class="h-full w-2/3 flex flex-row justify-start items-center">
                <span class="text-font-gray font-semibold text-tiny truncate-2">{{ this.opportunity.client_name }}</span>
            </div>
            <div class="h-full w-2"></div>
            <div class="h-full w-1/3 flex flex-row justify-end items-center">
                <span class="text-aux text-right font-semibold text-xs truncate-2">{{ this.opportunity.competency || 'N/A' }}</span>
            </div>

        </div>

        <div class="h-8 flex flex-row flex-none">

            <div class="h-full w-1/2 flex flex-row justify-start items-center">
                <span class="text-xs uppercase text-font-gray">{{ this.opportunity.ep_name }}</span>
            </div>

            <div class="h-full w-1/2 flex flex-row justify-end items-center">
                <span class="text-white font-semibold uppercase text-xs">{{ this.opportunity.sector_ambiciona }}</span>
            </div>

        </div>

        <div class="h-10 flex flex-row justify-start items-center">

            <span class="mr-2 text-font-gray font-semibold">Opportunity Value</span>
            <span class="mr-auto font-semibold">{{ this.opportunity.valor_precio | reduceBigNumbers() }}€</span>
            <span class="mr-2 text-font-gray font-semibold">Margin</span>
            <span class="font-semibold">{{ this.opportunity.margen | reduceBigNumbers(2) }}%</span>

        </div>

    </div>

</template>

<script>
    export default {
        name: "OpportunityDesktop",
        props: ['opportunity', 'sectors'],
        methods:{
            percentageColor(percentage){

                switch (percentage) {
                    case '25':
                        return 'text-percentage-25'
                        break;
                    case '50':
                        return 'text-percentage-50'
                        break;
                    case '75':
                        return 'text-percentage-75'
                        break;
                    case '100':
                        return 'text-percentage-100'
                        break;
                }

            }
        },
        computed: {
            legendColors() { return this.$legendColorsBySector(this.sectors) }
        }
    }
</script>